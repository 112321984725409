import React from 'react';
import {
  CheckBoxChildItem,
  CheckboxWithChildItems,
} from './CheckBoxWithChildItems';

export interface CheckBoxTreeProps {
  items: CheckBoxChildItem[];
  selected: string[];
  onSelect: (value: string[] | null) => any;
  TopSectionComponent?: React.ReactNode;
  type: 'checkbox' | 'radio';
}

export const CheckBoxTree = React.memo(
  ({
    items,
    onSelect,
    selected,
    TopSectionComponent,
    type,
  }: CheckBoxTreeProps) => {
    return items.length ? (
      <div className="rwe-check-box-tree">
        {TopSectionComponent}
        {items.map((item) => (
          <CheckboxWithChildItems
            {...item}
            key={item.value || item.text}
            selected={selected}
            onSelect={onSelect}
            type={type}
          />
        ))}
      </div>
    ) : null;
  },
);
