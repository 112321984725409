import {MarkerProps, Marker, InfoWindow} from '@react-google-maps/api';
import React, {useCallback, useMemo} from 'react';
import {colors} from '../theme';
import {
  MapInfoWindowContentProps,
  MapInfoWindowContent,
} from './MapInfoWindowContent';
import {useMapMarkerWithInfoWindowContext} from './MapMarkerWithInfoWindowContext';

const resolveMapMarkerIconConfig = (): google.maps.Symbol => ({
  path:
    'M16 20C13.8 20 12 18.2 12 16C12 13.8 13.8 12 16 12C18.2 12 20 13.8 20 16C20 18.2 18.2 20 16 20ZM16 0C7.6 0 0 6.44 0 16.4C0 23.04 5.34 30.9 16 40C26.66 30.9 32 23.04 32 16.4C32 6.44 24.4 0 16 0Z',
  fillColor: colors.secondary.red,
  fillOpacity: 1,
  strokeWeight: 0,
  anchor: new google.maps.Point(16, 40 /* icon height */),
});

const resolveOptions = (): google.maps.InfoWindowOptions => ({
  pixelOffset: new google.maps.Size(0, -40 /* -icon height */),
});

export interface MapMarkerWithInfoWindowProps
  extends MapInfoWindowContentProps,
    Omit<MarkerProps, 'children' | 'mapPaneName'> {
  markerId: string;
  popupHref?: string;
  showInfoWindow: boolean;
  onMarkerClick: (markerId: string) => any;
}

export const MapMarkerWithInfoWindow = React.memo(
  ({
    markerId,
    headingText,
    addressLines,
    popupHref,
    showInfoWindow,
    onMarkerClick,
    ...infoBoxProps
  }: MapMarkerWithInfoWindowProps) => {
    const handleMarkerClick = useCallback(() => {
      onMarkerClick(markerId);
    }, [markerId, onMarkerClick]);

    const mapMarkerIconConfig = useMemo(resolveMapMarkerIconConfig, []);
    const options = useMemo(resolveOptions, []);

    return (
      <>
        <Marker
          {...infoBoxProps}
          icon={mapMarkerIconConfig}
          onClick={handleMarkerClick}
        />
        {showInfoWindow ? (
          <InfoWindow position={infoBoxProps.position} options={options}>
            <MapInfoWindowContent
              headingText={headingText}
              addressLines={addressLines}
              href={popupHref}
            />
          </InfoWindow>
        ) : null}
      </>
    );
  },
);

export type ContextControlledMapMarkerWithInfoWindowProps = Omit<
  MapMarkerWithInfoWindowProps,
  'onMarkerClick' | 'showInfoWindow'
>;

export const ContextControlledMapMarkerWithInfoWindow = React.memo(
  (props: ContextControlledMapMarkerWithInfoWindowProps) => {
    const context = useMapMarkerWithInfoWindowContext();

    const showInfoWindow = useMemo(
      () => context.selectedMapItemKey === props.markerId,
      [context.selectedMapItemKey, props.markerId],
    );

    return (
      <MapMarkerWithInfoWindow
        {...props}
        showInfoWindow={showInfoWindow}
        onMarkerClick={context.setSelectedMapItemKey}
      />
    );
  },
);
