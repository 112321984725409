import React, {useMemo} from 'react';
import {useElementInteraction} from '../../../../hooks/use-element-interaction';
import {AnchorOrButton} from '../link/AnchorOrButton';
import {colors} from '../theme';

interface BasePillProps {
  backgroundColor: string;
  children: string;
  isActive?: boolean;
}

interface PillWithHrefProps extends BasePillProps {
  href: string;
  onClick?: undefined;
}

interface PillWithOnClickProps extends BasePillProps {
  href?: undefined;
  onClick: () => any;
}

export type PillProps = PillWithHrefProps | PillWithOnClickProps;

export const Pill = React.memo(
  ({backgroundColor, children, isActive, ...rest}: PillProps) => {
    const {hasMouse, onMouseEnter, onMouseLeave} = useElementInteraction();
    const style = useMemo(
      () =>
        isActive || hasMouse
          ? {
              backgroundColor,
              borderColor: backgroundColor,
              color: colors.primary.white,
            }
          : undefined,
      [backgroundColor, hasMouse, isActive],
    );

    const innerFragment = useMemo(
      () => (
        <div
          className="rwe-pill"
          style={style}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {children}
        </div>
      ),
      [children, onMouseEnter, onMouseLeave, style],
    );

    if (rest.onClick) {
      return (
        <AnchorOrButton as="button" {...rest}>
          {innerFragment}
        </AnchorOrButton>
      );
    }

    return (
      <AnchorOrButton as="anchor" {...rest}>
        {innerFragment}
      </AnchorOrButton>
    );
  },
);
