import React, {useCallback, useMemo} from 'react';
import {CheckBox, CheckBoxSelectLevel} from './CheckBox';

export interface CheckBoxChildItem {
  text: string;
  value?: string | null;
  items?: CheckBoxChildItem[];
}

const getCheckBoxSelectLevel = (
  value: string | null,
  childItems: CheckBoxChildItem[],
  selected: string[],
  type: 'checkbox' | 'radio',
): CheckBoxSelectLevel => {
  if (value === null && selected.length === 0) return 'full';

  if (value === null) return 'none';

  if (typeof selected === 'string') return selected === value ? 'full' : 'none';

  if (!selected.length) return 'none';

  if (!childItems.length) return selected.includes(value) ? 'full' : 'none';

  const checkBoxItemsSelectLevelFromChildItems = childItems.map((itemInItems) =>
    getCheckBoxSelectLevel(
      itemInItems.value !== null
        ? itemInItems.value ?? itemInItems.text
        : itemInItems.value,
      itemInItems.items || [],
      selected,
      type,
    ),
  );

  const reducedCheckBoxItemsSelectLevelFromChildItems = checkBoxItemsSelectLevelFromChildItems.reduce(
    (previousValue, currentValue) => {
      switch (previousValue) {
        case 'full':
          return currentValue === 'full' ? 'full' : 'partial';
        case 'partial':
          return 'partial';
        case 'none':
        default:
          return currentValue === 'none' ? 'none' : 'partial';
      }
    },
  );

  return reducedCheckBoxItemsSelectLevelFromChildItems;
};

export interface CheckBoxWithChildItemsProps extends CheckBoxChildItem {
  selected: string[];
  onSelect?: (value: string[] | null) => any;
  type: 'checkbox' | 'radio';
}

export const CheckboxWithChildItems = React.memo(
  ({
    text,
    items = [],
    value,
    selected,
    onSelect,
    type,
  }: CheckBoxWithChildItemsProps) => {
    const checkboxSelectLevel = useMemo(
      () =>
        getCheckBoxSelectLevel(
          value !== null ? value ?? text : value,
          items,
          selected,
          type,
        ),
      [value, items, selected, text, type],
    );

    const handleSelect = useCallback(() => {
      if (!onSelect) return;

      const getValuesFromRecursiveChildItems = (
        childItems: CheckBoxChildItem[],
      ): string[] => {
        return [
          ...childItems
            .filter((childItem) => !childItem.items || !childItem.items.length)
            .map((childItem) => childItem.value || childItem.text),
          ...childItems
            .filter((childItem) => childItem.items && childItem.items.length)
            .map((childItem) =>
              getValuesFromRecursiveChildItems(
                childItem.items as CheckBoxChildItem[],
              ),
            )
            .flat(),
        ];
      };

      if (items.length) {
        onSelect(getValuesFromRecursiveChildItems(items));
      } else {
        onSelect(value !== null ? [value ?? text] : null);
      }
    }, [items, onSelect, text, value]);

    return (
      <>
        <CheckBox
          selected={checkboxSelectLevel}
          value={value}
          onSelect={handleSelect}
          type={type}
        >
          {text}
        </CheckBox>
        {items && items.length ? (
          <div className="rwe-check-box-child-items">
            {items.map((item) => (
              <CheckboxWithChildItems
                {...item}
                key={item.value || item.text}
                selected={selected}
                onSelect={onSelect}
                type={type}
              />
            ))}
          </div>
        ) : null}
      </>
    );
  },
);
