import React from 'react';
import {colors} from '../theme';

interface CheckBoxIconProps {
  color?: any;
  width?: number;
  height?: number;
  filled?: 'none' | 'partial' | 'full';
}

export const CheckBoxIcon = React.memo(
  ({
    color = colors.primary.teal,
    width = 24,
    height = 24,
    filled = 'none',
  }: CheckBoxIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {filled === 'full' ? (
        <rect x="5" y="5" width="14" height="14" fill={color} />
      ) : null}
      {filled === 'partial' ? (
        <path d="M19 19L5 5V19H19Z" fill={color} />
      ) : null}
      <path
        d="M19 3H5C3.89 3 3 3.89 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V5C21 3.89 20.1 3 19 3ZM19 5V19H5V5H19Z"
        fill={colors.primary.charcoalBlack}
      />
    </svg>
  ),
);
