import React from 'react';
import {useNavbarContext} from '../navbar/NavbarContextProvider';
import {Drawer, DrawerProps} from './Drawer';

export type NavbarAvoidingDrawerProps = DrawerProps;

export const NavbarAvoidingDrawer = React.memo(
  ({style, ...rest}: NavbarAvoidingDrawerProps) => {
    const {isNavbarHiding, navbarContainerElementRef} = useNavbarContext();
    const showingNavbarDrawerStyle = isNavbarHiding
      ? {top: 0}
      : {top: navbarContainerElementRef?.current?.clientHeight || 0};

    return (
      <Drawer
        {...rest}
        style={
          style
            ? {...style, ...showingNavbarDrawerStyle}
            : showingNavbarDrawerStyle
        }
      />
    );
  },
);
