import React from 'react';
import {CheckBoxTree, CheckBoxTreeProps} from '../check-box/CheckBoxTree';
import {CloseIcon} from '../icon/CloseIcon';
import {AnchorOrButton} from '../link/AnchorOrButton';
import {
  NavbarAvoidingDrawer,
  NavbarAvoidingDrawerProps,
} from './NavbarAvoidingDrawer';

export interface FiltersDrawerProps
  extends Omit<
    NavbarAvoidingDrawerProps,
    'children' | 'HeaderComponent' | 'style'
  > {
  filters: (CheckBoxTreeProps & {title: string})[];
  filterText: string;
  onClear: () => any;
}

export const FiltersDrawer: React.FC<FiltersDrawerProps> = React.memo(
  ({filters, onClose, onClear, filterText, ...rest}) => {
    return (
      <NavbarAvoidingDrawer
        {...rest}
        onClose={onClose}
        HeaderComponent={
          <>
            <div className="rwe-mobile-filters-drawer__header">
              <div className="rwe-mobile-filters-drawer__title">
                <div className="rwe-h4-alt">{filterText}</div>
              </div>
              <div className="rwe-mobile-filters-drawer__clear-button">
                <AnchorOrButton as="button" onClick={onClear}>
                  Clear all
                </AnchorOrButton>
              </div>
            </div>
            <AnchorOrButton as="button" onClick={onClose}>
              <CloseIcon />
            </AnchorOrButton>
          </>
        }
      >
        {filters.map(({title, ...filter}) => (
          <CheckBoxTree
            {...filter}
            TopSectionComponent={
              <div className="rwe-check-box-tree__top-section">
                <p className="rwe-p2 rwe-p2--medium">{title}</p>
              </div>
            }
          />
        ))}
      </NavbarAvoidingDrawer>
    );
  },
);
