import React from 'react';
import {RunwayEastLink} from '../link/RunwayEastLink';

export interface MapInfoWindowContentProps {
  headingText?: string;
  addressLines?: string[];
  href?: string;
}

export const MapInfoWindowContent = React.memo(
  ({headingText, addressLines, href}: MapInfoWindowContentProps) => (
    <div className="rwe-map-popup-content">
      {headingText ? (
        <div className="rwe-map-popup-content__heading">{headingText}</div>
      ) : null}
      {addressLines ? (
        <div className="rwe-map-popup-content__address-lines">
          {addressLines.map((addressLine) => (
            <div className="rwe-p3">{addressLine}</div>
          ))}
        </div>
      ) : null}
      {href ? (
        <div className="rwe-map-popup-content__link">
          <RunwayEastLink href={href}>View in Google Maps</RunwayEastLink>
        </div>
      ) : null}
    </div>
  ),
);
