import React, {useCallback, useContext, useMemo, useState} from 'react';

interface MapMarkerWithInfoWindowContextValue {
  selectedMapItemKey: string | undefined;
  setSelectedMapItemKey: (selectedMapItemKey: string) => any;
}

const MapMarkerWithInfoWindowContext = React.createContext<MapMarkerWithInfoWindowContextValue>(
  {
    selectedMapItemKey: undefined,
    setSelectedMapItemKey: () => null,
  },
);

interface MapMarkerWithInfoWindowContextProviderProps {
  children: React.ReactNode;
  selectedMapItemKey?: string | undefined;
}

export const MapMarkerWithInfoWindowContextProvider = React.memo(
  ({
    selectedMapItemKey,
    children,
  }: MapMarkerWithInfoWindowContextProviderProps) => {
    const [selected, setSelected] = useState<string | undefined>(
      selectedMapItemKey,
    );

    const setSelectedMapItemKey = useCallback(
      (mapItemKey: string) => {
        setSelected(mapItemKey === selected ? undefined : mapItemKey);
      },
      [selected],
    );

    const value = useMemo(
      () => ({selectedMapItemKey: selected, setSelectedMapItemKey}),
      [selected, setSelectedMapItemKey],
    );

    return (
      <MapMarkerWithInfoWindowContext.Provider value={value}>
        {children}
      </MapMarkerWithInfoWindowContext.Provider>
    );
  },
);

export const useMapMarkerWithInfoWindowContext = (): MapMarkerWithInfoWindowContextValue => {
  const context = useContext(MapMarkerWithInfoWindowContext);

  if (!context)
    throw new Error('Missing MapMarkerWithInfoWindowContext Provider');

  return context;
};
