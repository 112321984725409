import React, {useEffect, useMemo, useState} from 'react';
import {GoogleMap, LoadScript} from '@react-google-maps/api';
import {
  ContextControlledMapMarkerWithInfoWindow,
  ContextControlledMapMarkerWithInfoWindowProps,
} from './MapMarkerWithInfoWindow';
import {MapMarkerWithInfoWindowContextProvider} from './MapMarkerWithInfoWindowContext';

export interface MapProps {
  center?: google.maps.LatLngLiteral;
  zoom?: number;
  blockId?: string;
  blockName?: string;
  LaptopVisible?: string;
  MobileVisible?: string;
  markers?: ContextControlledMapMarkerWithInfoWindowProps[];
  selectedMapItemKey?: string | undefined;
  autoPanToMarkers?: boolean;
  autoPanZoomLevel?: number;
}

export const Map = React.memo(
  ({
    center = {
      lat: 0,
      lng: 0,
    },
    zoom = 15,
    markers = [],
    selectedMapItemKey,
    blockId,
    blockName,
    LaptopVisible,
    MobileVisible,
    autoPanToMarkers = false,
    autoPanZoomLevel,
  }: MapProps) => {
    const [ready, setReady] = useState(false);
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const renderedMarkers = useMemo(() => {
      if (!markers.length) return null;

      return (
        <MapMarkerWithInfoWindowContextProvider
          selectedMapItemKey={selectedMapItemKey}
        >
          {markers.map((marker) => (
            <ContextControlledMapMarkerWithInfoWindow
              key={marker.markerId}
              {...marker}
            />
          ))}
        </MapMarkerWithInfoWindowContextProvider>
      );
    }, [markers, selectedMapItemKey]);

    useEffect(() => {
      if (autoPanToMarkers && window.google) {
        const bounds = new window.google.maps.LatLngBounds();
        markers.forEach((marker) => {
          bounds.extend(marker.position);
        });
        if (autoPanZoomLevel) {
          map?.setCenter(bounds.getCenter());
          map?.setZoom(autoPanZoomLevel);
        } else {
          map?.fitBounds(bounds);
        }
      }
    }, [map, markers, autoPanToMarkers, autoPanZoomLevel]);

    return (
      <LoadScript
        googleMapsApiKey={process.env.GOOGLE_MAPS_API_KEY as string}
        onLoad={() => setReady(true)}
      >
        {ready && (
          <GoogleMap
            mapContainerClassName="rwe-google-map"
            center={center}
            onLoad={setMap}
            onUnmount={() => setMap(null)}
            zoom={zoom}
          >
            {renderedMarkers}
          </GoogleMap>
        )}
      </LoadScript>
    );
  },
);
