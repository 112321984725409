import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {DropdownWithCheckBoxTreeProps} from '../dropdown/DropdownWithCheckBoxTree';
import {CheckBoxChildItem} from '../check-box/CheckBoxWithChildItems';
import {CheckBoxTree} from '../check-box/CheckBoxTree';
import {Dropdown} from '../dropdown/Dropdown';

const getValueTextMapFromTree = (items: CheckBoxChildItem[]) => {
  const traverse = (item: CheckBoxChildItem): Record<string, string>[] =>
    [{[item.value || item.text]: item.text}].concat(
      (item.items || [])
        .map((child) =>
          traverse(child)
            .map((arr) =>
              [{[child.value || child.text]: child.text}].concat(arr),
            )
            .flat(),
        )
        .flat(),
    );

  const arrayToMap = (
    array: Record<string, string>[],
  ): Record<string, string> =>
    array.reduce((prev, curr) => ({...prev, ...curr}), {});

  return arrayToMap(items.map(traverse).flat());
};

interface RenderButtonArg {
  toggleDropdown: () => any;
  isDropdownShowing: boolean;
  selected: string[];
  valueTextMap: Record<string, string>;
  title: string;
}

export interface ButtonWithCheckBoxTreeDropdownProps
  extends Omit<DropdownWithCheckBoxTreeProps, 'isShowing'> {
  title: string;
  renderButton: (arg: RenderButtonArg) => React.ReactElement;
}

export const ButtonWithCheckBoxTreeDropdown = React.memo(
  ({
    title,
    selected,
    items,
    rightAligned,
    renderButton,
    ...rest
  }: ButtonWithCheckBoxTreeDropdownProps) => {
    const dropdownContainerRef = useRef<HTMLDivElement | null>(null);
    const [isDropdownShowing, setIsDropdownShowing] = useState(false);

    const valueTextMap = useMemo(() => {
      return getValueTextMapFromTree(items);
    }, [items]);

    const toggleDropdown = useCallback(() => {
      setIsDropdownShowing((value) => !value);
    }, []);

    const renderedButton = useMemo(
      () =>
        renderButton({
          isDropdownShowing,
          selected,
          title,
          toggleDropdown,
          valueTextMap,
        }),
      [
        renderButton,
        isDropdownShowing,
        selected,
        title,
        toggleDropdown,
        valueTextMap,
      ],
    );

    useEffect(() => {
      if (!isDropdownShowing) return () => null;

      const handleClick = (e: MouseEvent) => {
        if (!e.target) return;
        if (!dropdownContainerRef.current) return;

        if (dropdownContainerRef.current.contains(e.target as Node)) return;

        setIsDropdownShowing(false);
      };

      window.addEventListener('click', handleClick);

      return () => {
        window.removeEventListener('click', handleClick);
      };
    }, [isDropdownShowing]);

    return (
      <div className="rwe-filter">
        {renderedButton}

        <div ref={dropdownContainerRef} className="rwe-filter__dropdown">
          <Dropdown isShowing={isDropdownShowing} rightAligned={rightAligned}>
            <CheckBoxTree selected={selected} items={items} {...rest} />
          </Dropdown>
        </div>
      </div>
    );
  },
);
