import React, {useCallback, useMemo, useState} from 'react';
import {RunwayEastButton} from '../button/RunwayEastButton';
import {FiltersDrawer, FiltersDrawerProps} from '../drawer/FiltersDrawer';

interface RenderButtonArg {
  showDrawer: () => any;
  isDrawerShowing: boolean;
  numberOfSelectedFilters: number;
  numberOfResults?: number;
}

export interface ButtonWithCheckBoxTreeDrawerProps
  extends Omit<
    FiltersDrawerProps,
    'filterText' | 'isOpen' | 'onClose' | 'FooterComponent'
  > {
  numberOfResults?: number;
  renderButton: (arg: RenderButtonArg) => React.ReactElement;
  drawerButtonText: string;
  onSubmit?: () => any;
}

export const ButtonWithCheckBoxTreeDrawer = React.memo(
  ({
    filters,
    onClear,
    numberOfResults,
    renderButton,
    drawerButtonText,
    onSubmit,
  }: ButtonWithCheckBoxTreeDrawerProps) => {
    const [isDrawerShowing, setIsDrawerShowing] = useState(false);

    const numberOfSelectedFilters = useMemo(
      (): number =>
        filters
          .map((filter) => (filter.selected.length ? 1 : 0) as number)
          .reduce((a, b) => a + b),
      [filters],
    );

    const filterText = useMemo(() => {
      if (!numberOfSelectedFilters) return 'Filters';

      return `Filters (${numberOfSelectedFilters})`;
    }, [numberOfSelectedFilters]);

    const showDrawer = useCallback(() => setIsDrawerShowing(true), []);
    const hideDrawer = useCallback(() => setIsDrawerShowing(false), []);
    const handleFooterComponentButtonClick = useCallback(() => {
      if (onSubmit) onSubmit();
      hideDrawer();
    }, [hideDrawer, onSubmit]);

    const renderedButton = useMemo(
      () =>
        renderButton({
          showDrawer,
          isDrawerShowing,
          numberOfResults,
          numberOfSelectedFilters,
        }),
      [
        isDrawerShowing,
        numberOfResults,
        numberOfSelectedFilters,
        renderButton,
        showDrawer,
      ],
    );

    return (
      <>
        {renderedButton}
        <FiltersDrawer
          isOpen={isDrawerShowing}
          onClose={hideDrawer}
          filters={filters}
          filterText={filterText}
          onClear={onClear}
          FooterComponent={
            <RunwayEastButton
              variant="primary"
              blockButton
              onClick={handleFooterComponentButtonClick}
            >
              {drawerButtonText}
            </RunwayEastButton>
          }
        />
      </>
    );
  },
);
