import {useRouter} from 'next/router';
import {useCallback, useEffect, useRef, useState} from 'react';

const isArraySame = <Primitive extends string | number | boolean>(
  array1: Primitive[],
  array2: Primitive[],
) =>
  array1.every((value) => array2.includes(value)) &&
  array2.every((value) => array1.includes(value));

interface BaseUseFiltersOptions {
  updateRouterQueryOnChange?: boolean;
  type: 'multipleSelect' | 'singleSelect';
}

interface UseFiltersOptionsWithRouterQueryKey extends BaseUseFiltersOptions {
  routerQueryKey: string;
  queryValueResolver?: undefined;
  routerQueryResolver?: undefined;
}

interface UseFiltersOptionsWithResolver extends BaseUseFiltersOptions {
  routerQueryKey?: undefined;
  queryValueResolver: (
    routerQuery: Record<string, string | string[] | undefined>,
  ) => string[];
  routerQueryResolver: (
    selectedValues: string[],
    routerQuery: Record<string, string | string[] | undefined>,
  ) => Record<string, string | string[] | undefined>;
}
export type UseFiltersOptions =
  | UseFiltersOptionsWithRouterQueryKey
  | UseFiltersOptionsWithResolver;

export interface UseFiltersReturnValue {
  selectedValues: string[];
  onValueSelect: (value: string[] | null) => any;
  setSelectedValues: (values: string[]) => any;
}

export const useFilter = ({
  updateRouterQueryOnChange = false,
  type,
  routerQueryKey,
  queryValueResolver,
  routerQueryResolver,
}: UseFiltersOptions): UseFiltersReturnValue => {
  const provisionedSelectedValuesFromRouterQuery = useRef(false);
  const router = useRouter();

  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const onValueSelect = useCallback(
    (value: string[] | null) => {
      setSelectedValues((currentSelected) => {
        if (value === null) return [];
        if (type === 'singleSelect') return [value[0]];

        const currentlySelectedArray = Array.isArray(currentSelected)
          ? currentSelected
          : [currentSelected];

        return value.every((valueItem) =>
          currentlySelectedArray.includes(valueItem),
        )
          ? currentlySelectedArray.filter(
              (currentSelectedItem) => !value.includes(currentSelectedItem),
            )
          : [
              ...currentlySelectedArray.filter(
                (currentSelectedItem) => !value.includes(currentSelectedItem),
              ),
              ...value,
            ];
      });
    },
    [type],
  );

  /**
   * Provisions the selectedValues with the router query param value on first load
   */
  useEffect(() => {
    if (!router.isReady) return;
    const queryValue =
      routerQueryKey !== undefined
        ? router.query[routerQueryKey]
        : queryValueResolver && queryValueResolver(router.query);
    if (provisionedSelectedValuesFromRouterQuery.current) return;
    if (!queryValue) {
      provisionedSelectedValuesFromRouterQuery.current = true;
      return;
    }
    if (
      (Array.isArray(selectedValues) &&
        isArraySame(
          selectedValues,
          typeof queryValue === 'string' ? [queryValue] : queryValue,
        )) ||
      selectedValues === queryValue
    ) {
      provisionedSelectedValuesFromRouterQuery.current = true;
      return;
    }

    setSelectedValues(
      typeof queryValue === 'string' ? [queryValue] : queryValue,
    );

    provisionedSelectedValuesFromRouterQuery.current = true;
  }, [
    queryValueResolver,
    router.isReady,
    router.query,
    routerQueryKey,
    selectedValues,
    type,
  ]);

  /**
   * Updates the URL with query params whenever the filters change
   */
  useEffect(() => {
    if (!updateRouterQueryOnChange) return;
    if (!router.isReady) return;
    if (!provisionedSelectedValuesFromRouterQuery.current) return;

    const queryValue =
      routerQueryKey !== undefined
        ? router.query[routerQueryKey]
        : queryValueResolver && queryValueResolver(router.query);

    if (
      ((Array.isArray(queryValue) || typeof queryValue === 'string') &&
        isArraySame(
          selectedValues,
          typeof queryValue === 'string' ? [queryValue] : queryValue || [],
        )) ||
      selectedValues === queryValue
    )
      return;

    if (
      typeof queryValue === 'object' &&
      !Array.isArray(queryValue) &&
      isArraySame(
        (queryValueResolver && queryValueResolver(queryValue)) || [],
        selectedValues,
      )
    )
      return;

    const resolvedRouterQuery =
      (routerQueryResolver &&
        routerQueryResolver(selectedValues, router.query)) ||
      {};

    router.replace(
      {
        query: {
          ...(routerQueryKey
            ? {...router.query, [routerQueryKey]: selectedValues}
            : resolvedRouterQuery),
        },
      },
      undefined,
      {shallow: true},
    );
    /**
     * We disable react-hooks/exhaustive-deps because passing `
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerQueryKey, selectedValues]);

  return {
    selectedValues,
    onValueSelect,
    setSelectedValues,
  };
};
