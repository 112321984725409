import React, {useMemo, useRef, useImperativeHandle} from 'react';

const breakpoints = [
  'mobile',
  'tablet',
  'desktop',
  'large-desktop',
  'extra-large-desktop',
];

export type NumberOfFlexGridColumns = 1 | 2 | 3 | 4;

export type ItemsPerRowPerBreakpoint = [
  number | undefined,
  number | undefined,
  number | undefined,
  number | undefined,
  number | undefined,
];

type WrappedChildWithRef = [
  React.ReactElement,
  React.RefObject<HTMLDivElement>,
];

export interface FlexGridRefAttributes {
  wrappedChildrenWithRefs: WrappedChildWithRef[];
  elementRef: React.MutableRefObject<HTMLDivElement | null>;
}
export interface FlexGridProps {
  /**
   * [xs, sm, md, lg, xlg]
   */
  itemsPerRowPerBreakpoint?: ItemsPerRowPerBreakpoint;
  children: React.ReactNodeArray;
  childClassNameResolver?: (index: number) => string[] | undefined;
  onScroll?: () => any;
  noSpacing?: boolean;
}

/**
 * The purpose of the SPACERS is to fill the remaining space of the `FlexGrid` with elements
 */
const NUMBER_OF_SPACERS = 10;
const SPACERS = new Array(NUMBER_OF_SPACERS)
  .fill(null)
  .map(() => (
    <div className="rwe-flex-grid__item rwe-flex-grid__item--spacer" />
  ));

export const FlexGrid = React.memo(
  React.forwardRef<FlexGridRefAttributes, FlexGridProps>(
    (
      {
        itemsPerRowPerBreakpoint,
        children,
        onScroll,
        noSpacing,
        childClassNameResolver,
      },
      ref,
    ) => {
      const flexGridElementRef = useRef<HTMLDivElement | null>(null);

      const flexGridClassNames = useMemo((): string => {
        const classNames = ['rwe-flex-grid'];

        if (itemsPerRowPerBreakpoint) {
          const columnOrCarouselClassNames = itemsPerRowPerBreakpoint
            .map((itemsPerRow, index) => {
              const breakpoint = breakpoints[index];

              return itemsPerRow
                ? `rwe-flex-grid--col-${breakpoint}-${itemsPerRow}`
                : `rwe-flex-grid--carousel-${breakpoint}`;
            })
            .filter(Boolean) as string[];

          classNames.push(...columnOrCarouselClassNames);
        }

        return classNames.join(' ');
      }, [itemsPerRowPerBreakpoint]);

      const wrappedChildrenWithRefs = useMemo(
        (): WrappedChildWithRef[] =>
          children.map((child, index) => {
            const wrappedChildRef = React.createRef<HTMLDivElement>();
            const classNames = [
              'rwe-flex-grid__item',
              ...(noSpacing ? ['-no-spacing'] : []),
              ...(childClassNameResolver
                ? childClassNameResolver(index) || []
                : []),
            ];
            const wrappedChild = (
              <div ref={wrappedChildRef} className={classNames.join(' ')}>
                {child}
              </div>
            );
            return [wrappedChild, wrappedChildRef];
          }),
        [children, noSpacing, childClassNameResolver],
      );

      const wrappedChildren = useMemo(
        () => wrappedChildrenWithRefs.map(([wrappedChild]) => wrappedChild),
        [wrappedChildrenWithRefs],
      );

      useImperativeHandle(
        ref,
        (): FlexGridRefAttributes => ({
          wrappedChildrenWithRefs,
          elementRef: flexGridElementRef,
        }),
        [wrappedChildrenWithRefs, flexGridElementRef],
      );

      return (
        <div
          ref={flexGridElementRef}
          className={flexGridClassNames}
          onScroll={onScroll}
        >
          {wrappedChildren}
          {!itemsPerRowPerBreakpoint ? SPACERS : null}
        </div>
      );
    },
  ),
);
