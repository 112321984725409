import React, {useCallback} from 'react';
import {CheckBoxIcon} from '../icon/CheckBoxIcon';
import {RadioButtonIcon} from '../icon/RadioButtonIcon';

export type CheckBoxSelectLevel = 'partial' | 'full' | 'none';

export interface CheckBoxProps {
  selected?: CheckBoxSelectLevel;
  onSelect?: (value: string | null) => any;
  children: string;
  value?: string | null;
  type: 'checkbox' | 'radio';
}

export const CheckBox = React.memo(
  ({children, onSelect, selected, value, type}: CheckBoxProps) => {
    const handleSelect = useCallback(() => {
      if (!onSelect) return;

      onSelect(value !== null ? value ?? children : null);
    }, [onSelect, value, children]);

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div className="rwe-check-box" onClick={handleSelect}>
        <div className="rwe-check-box__input">
          <input
            type="checkbox"
            onChange={handleSelect}
            checked={selected !== 'none'}
          />
          {type === 'checkbox' ? (
            <CheckBoxIcon filled={selected} />
          ) : (
            <RadioButtonIcon filled={selected} />
          )}
        </div>
        <div className="rwe-check-box__text">
          <div className="rwe-p2">{children}</div>
        </div>
      </div>
    );
  },
);
